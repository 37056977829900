import React from 'react';
import '../App.css';
import './AboutSection.css';

function AboutSection() {
return(
    <div className='gallery-container'>
        <div class="row"> 
        <div class="column-gallery">
        <img src="galeria2.jpg" ></img>
        <img src="galeria4.jpg"></img>
        <img src="galeria3.jpg" ></img>
        </div>
        <div class="column-gallery">
        <img src="galeria1.jpg" ></img>
        <img src="galeria7.jpg" ></img>
        <img src="galeria6.jpg" ></img>
        </div> 
        <div class="column-gallery">
        <img src="galeria5.jpg" ></img>
        <img src="galeria9.jpg" ></img>
        <img src="galeria10.jpg" ></img>
        </div> 
        <div class="column-gallery">
        <img src="galeria11.jpg" ></img>
        <img src="galeria8.jpg" ></img>
        <img src="IMG_8881.JPG" ></img>
        </div> 
                
        </div>
    </div>
    );
}

export default AboutSection;