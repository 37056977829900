import React from 'react';
import '../App.css';
import './Header.css';

function Header() {
  return (
    <div className='header-container'>

          <hr></hr> 
              <div className='header-content' >
                
            <img src="logo1.jpg" className='img-logo'></img> 
            <div className='header-content-napis'>
                      <h2 style={{fontWeight: "bold"}}>mgr Zbigniew Pełszyński</h2><h3>Mistrz bioenergoterapii, radiesteta, healer</h3>
                      
                      <h2 style={{fontWeight: "bold"}}>dr Elżbieta Olechno-Pełszyńska</h2>
                      <h3>Specjalista ginekologii i położnictwa </h3>
                         
              </div>
             
              <div className='div-header'>
                    <h2><img src="maps-and-flags.png" height='25px' style={{marginRight: "5%"}}></img>Lokalizacja</h2>
                    <h3 style={{fontWeight: "bold"}}>ul. Al. Józefa Piłsudskiego 6/3</h3>
                    <h3>15-445, Białystok</h3>
              </div>
              <div className='div-header'>
                  <h2><img src="smartphone.png" height='25px'style={{marginRight: "5%"}}></img>Medycyna alternatywna</h2>
                  <h3>Rejestracja 9:00 - 17:00</h3>
                  <h3 style={{fontWeight: "bold"}}>tel. 885 303 377 </h3>
                </div>
                <div className='div-header'>
                  <h2><img src="smartphone.png" height='25px'style={{marginRight: "5%"}}></img>Ginekologia i położnictwo</h2>
                  <h3>Rejestracja 9:00 - 17:00</h3>
                  <h3 style={{fontWeight: "bold"}}>tel. 887 404 404</h3>
                </div>
                
                 <div className='div-header1' >

                <h2><img src="money.png"  height='25px' style={{marginRight: "5%"}}></img>Płatność </h2>
                      <h2 style={{fontWeight: "bold"}}>Tylko gotówka</h2>  
                </div>
          </div>
      
       <div className='footer-container' >
      <h3>&copy; Copyright 2021 Gabinet lekarski dr Elżbieta Olechno-Pełszyńska, ul. Al.Józefa Piłsudskiego 6/3, 15-445 Białystok</h3>
    
    </div>
    </div>
  );
}

export default Header;