
import '../App.css';
import './HomeSection.css';
import { Button } from './Button';
import { Helmet } from "react-helmet";
import React, {Component} from "react";

function HomeSection() {
return(
    <div className='homeSection-container'>
            <img src="IMG_8602_3.jpg" alt="Gabinet lekarski Białystok" width="100%" className='img'/>
           <div className='content'>
                <h1>Łączymy medycynę naturalną z akademicką </h1>
                <h2>Zadbaj o swoje zdrowie już dziś!</h2>
                 <h3>Tylko wizyty prywatne, bez kontraktu z NFZ</h3>
                <div className='hero-btns'>
                    <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                    >
                    UMÓW WIZYTĘ
                    </Button>
                </div>
               
            </div> 
    </div>
    );
}

export default HomeSection;