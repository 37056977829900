import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';


function Navbar(){
    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    
    return (
        <>
        <nav className='navbar' sticky="top">
            <div className='navbar-container'>
                <Link to='/' className='navbar-logo'>
                    GABINET LEKARSKI
                </Link>  
                <div className='menu-icon' onClick={handleClick}>
                  <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'} >
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onclick={closeMobileMenu}>
                            Start
                        </Link>
                    </li>
                        <li className='nav-item'>
                            <Link to='/medycyna-alternatywna' className='nav-links' onClick={() => setClick(!click)}>
                                Medycyna alternatywna 
                            </Link>
                        </li>
                    <li className='nav-item'>
                        <Link to='/ginekologia-poloznictwo' className='nav-links' onClick={() => setClick(!click)}>
                            Ginekologia i położnictwo
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/rodo' className='nav-links' onClick={() => setClick(!click)}>
                            RODO
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/galeria' className='nav-links' onClick={() => setClick(!click)}>
                            Galeria
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/kontakt' className='nav-links' onClick={() => setClick(!click)}>
                            Kontakt
                        </Link>
                    </li>
                   
                </ul>
            </div>
        </nav>
        </>
    )
}

export default Navbar