
import '../../App.css';
import '../Contact.css';
import emailjs from 'emailjs-com';
import { Map, Marker, GoogleApiWrapper , InfoWindow} from "google-maps-react";
import swal from 'sweetalert'
import React, {Component} from "react";

class Contact extends Component  {
  constructor(props) {
    super(props);
    this.state = { message: '',
                  title:'',
                  name: '',
                  email: '',
                  checkedRodo: false,
                  checkedSubmit: false, 
                  disabled:true
                 };
                 this.handleChangeRodo = this.handleChangeRodo.bind(this); 
  }
  handleChangeRodo() {
    this.setState({
        checkedRodo: !this.state.checkedRodo     
    })
  }

  handleSubmit(event) {
    console.log("klikniete")
    this.setState({
      checkedSubmit: !this.state.checkedSubmit    
  })
  }

  nameChange = (event) => {
    this.setState({name: event.target.value})
  }

  titleChange = (event) => {
    this.setState({title: event.target.value})
  }
  
 
  emailChange = (event) => {
    this.setState({email: event.target.value})
  }

 
  messageChange = (event) => {
    this.setState({message: event.target.value})
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const templateId = 'template_o0xz97i';

    this.sendFeedback(templateId, {
      message: this.state.message, 
      title: this.state.title,
      name: this.state.name, 
      email: this.state.email
     }
)

}
sendFeedback = (templateId, variables) => {
  window.emailjs.send(
    'gab-lek_service', templateId,
    variables
    ).then(res => {
      console.log("succes") ;
      swal("Sukces!", "Wiadomośc została wysłana!", "success");
    
        
         setTimeout(() => {
           window.location.reload();
        }, 2500);
      
    })
    .catch(err => {
      console.error('Email Error:', err)
    })
  
}

disable=()=>{
  console.log(this.state.checkedRodo)
   
   if(this.state.checkedRodo===true){
     console.log("zaznaczony")
     this.setState({disabled: true})
    
   }
   if(this.state.checkedRodo===false){
     console.log("odznaczony")
     this.setState({disabled: false})
   }
    console.log(this.state.disabled)
    
}

  onMarkerClick(props, marker, e) {
    swal("Gabinet Lekarski", "ul. Al. Józefa Piłsudskiego 6/3");
  }
  
  render() {

    const style = {
      width: '70%',
      height: '50%'
      }

  return (
    <div className='contact-container'>

        <div className='contact-first-coinatiner'>
      <div className='contact-text'  >
            
              <h1> Dane kontaktowe</h1>
              
              <h2><p style={{ marginTop: "5%"}}>GABINET LEKARSKI</p></h2>
              <h3><p  >Al. Józefa Piłsudskiego 6/3</p></h3>
              <h3><p >15-445 Białystok</p></h3>
              <h3><p >E-mail: gab.lek.bialystok@gmail.com</p></h3>
              <br></br>
              <h3><p>Medycyna alternatywna</p></h3>
              <h3><p>mgr Zbigniew Pełszyński</p></h3>
              <h3><p>tel. 885 303 377</p></h3>
              <br></br>
              <h3><p>Ginekologia i położnictwo</p></h3>
              <h3><p>dr Elżbieta Olechno-Pełszyńska</p></h3>
              <h3><p>tel. 887 404 404</p></h3>

      </div>

      <div className='contact-email' >
       
        <form className="test-mailing" onSubmit={this.handleSubmit}>

        <div className='email'>
          <h1>Wyślij wiadomość</h1>
          
              <input className="form-control email-inputs" name="user_name" type="text" 
                id="name" onChange={this.nameChange} required placeholder="Imię, Nazwisko" style={{fontSize: "20px", marginTop:"5%", width:"30%"}}/>

              <input className="form-control email-inputs" name="user_title" type="text" 
                id="name" onChange={this.titleChange} required placeholder="Tytuł" style={{fontSize: "20px", marginLeft:"1%", width:"30%"}}/> 
         
              <input className="form-control email-inputs" name="user_email" type="text"
                id="email" onChange={this.emailChange} required placeholder="E-mail"style={{ fontSize: "20px", marginLeft:"1%", width:"30%"}}/>
         
          <div >
            <textarea
              id="message"
              name="message"
              onChange={this.messageChange}
              placeholder="Wiadomość"
              required
              className="email-text-area form-control"
              rows="6"
              style={{fontSize: "20px", fontFamily: "sans-serif",height:"auto", width:"92%", marginTop:"1%"}}
            />
          </div>
          <div className='email-h3' style={{display: "inline-block",  marginTop: "20px", marginBottom:"1%", width:"92%",color: "#535353"}}>
          <input type="checkbox" id="rodo-contact" name="rodo-contact" onClick={this.disable} checked={ this.state.checkedRodo} onChange={ this.handleChangeRodo}/>

          <label for="rodo-contact" style={{fontSize: "0.9rem"}} >Wyrażam zgodę na przetwarzanie moich danych osobowych w powyższym formularzu przez Gabinet Lekarski dr Elżbieta Olechno-Pełszyńska, ul. Al.Józefa Piłsudskiego 6/3, 15-445 Białystok.</label>
       
           <input id="submit-contact" type="submit" value="Wyślij" checked={ this.state.checkedSubmit } disabled={this.state.disabled} onChange={ this.handleSubmit }  className="contact-submit" style={{width: "100%", fontSize: "20px", marginTop: "20px"}}  />
       </div> 
       </div>

      </form>
      </div>
      </div>

      <div className='contact-map'style={{marginTop: "10%"}}>
          <Map 
            google={this.props.google} 
            zoom={17}
            initialCenter={{
            lat: 53.13569,
            lng: 23.14926
            }}
            resetBoundsOnResize={true}
            style={{width: '100%', height: '80%'}}>
              <Marker onClick={this.onMarkerClick}
                title="Lokalizacja"
                id={1}
                draggable={true}
                >
               
              </Marker>
            </Map>
      </div>
  </div>
  );
}
}
export default GoogleApiWrapper({
  apiKey: ("AIzaSyCoiF7mcQFoeAa3XQl-rvDKiDOuvW9xuT0"),
  version: 3.44
 })(Contact);