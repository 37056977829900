
import '../App.css';
import './MedSection.css';
import {Link} from 'react-router-dom';
import  { useEffect } from "react";
import { Helmet } from "react-helmet";
import React, {Component} from "react";

function MedSection() {
    
const  useEffect = (() => {
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

return(
    <div className='med'>
            <div className='med-top'>
            <div className='med-top-text'>
            <h2 style={{fontWeight:"600"}}>Medycyna alternatywna</h2>
            <br></br>
            <h3><Link className='gin-a'  to="#bio" onClick={() => {let hero = document.getElementById("bio"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}> Bioenergoterapia </Link>
                •	 <Link className='gin-a' to="#pojecia" onClick={() => {let hero = document.getElementById("pojecia"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}> Ważne pojęcia </Link>
                 •	 <Link className='gin-a' to="#omnie" onClick={() => {let hero = document.getElementById("omnie"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}> O mnie </Link>
                  •	 <Link className='gin-a' to="#uslugi" onClick={() => {let hero = document.getElementById("uslugi"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}> Usługi </Link>
                   •	 <Link className='gin-a' to="#zabieg" onClick={() => {let hero = document.getElementById("zabieg"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}> Zabieg </Link>
                    •	 <Link className='gin-a' to="#uprawnienia" onClick={() => {let hero = document.getElementById("uprawnienia"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}> Uprawnienia </Link> 
                    •	 <Link className='gin-a' to="#biopol" onClick={() => {let hero = document.getElementById("biopol"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}> Członkowstwo BIOPOL </Link> 
                    •	 <Link className='gin-a' to="#warsztaty" onClick={() => {let hero = document.getElementById("warsztaty"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}> Warsztaty </Link></h3></div>
          </div>
        <div className='med-content1' >
            
            <div className='med-content1-text' >
                    <h1 id="bio">Bioenergoterapia</h1>
                    <br></br>
                    <h2>To zestaw niekonwencjonalnych metod uzdrawiania, których istotą jest oddziaływanie terapeuty na pacjenta poprzez dotyk lub za pomocą emitowanej energii biologicznej. 
                    Przychodząc do gabinetu specjalizującego się medycyną naturalną żądaj okazania certyfikatów bądź dyplomów poświadczających odpowiednie uprawnienia, gdyż niestety w dzisiejszych czasach dużo ludzi próbuje skorzystać na ludzkiej niewiedzy.
                    </h2>
                    <br></br>
                    <h2 style={{fontStyle:"italic", fontWeight:"600"}}>"Bioenergoterapia znana była przed wojną i po wojnie, istnieje także i w tych krajach, które nie przeżywają tak mocno kryzysu ekonomicznego jak my i kryzysu zaufania do medycyny oficjalnej."</h2>
                      </div> <div className='med-content1-img' >
                </div>
                 
        </div>

        <hr></hr> 

        <div className='med-content1'>
          
                <div className='med-content1-text'>
                    <h1 id="naczym">Na czym polega bioenergoterapia?</h1>
                    <br></br>
                        <h2>Podstawą bioenergoterapii jest podejście holistyczne, czyli całościowe do organizmu człowieka. </h2>
                        <br></br>
                        <h2 style={{fontStyle:"italic",fontWeight:"600"}}>"Jakże często pacjent od lekarza, którego absolutnie nie interesuje chory i jego psychika i który widzi zamiast człowieka tylko chore serce czy nerkę, trafia do osoby, która całą swoją uwagę kieruje na niego i daje mu ciepło swoich dłoni." 
                        </h2>
                        <h2>Należy usuwać nie objawy (np. ból głowy), lecz przyczyny powstawania dolegliwości. W centrum uwagi znajduje się Układ Energetyczny, stawiany na równi z systemem krążenia, oddychania, nerwowym czy pokarmowym. </h2>
                        <br></br>
                        <h2 style={{fontStyle:"italic",fontWeight:"600"}}>"Do bioenergoterapeuty trafia pacjent, któremu najpierw wypisywał receptę lekarz, dobrze nie widząc nawet jego twarzy. U bioenergoterapeuty sytuacja pacjenta zmienia się diametralnie, tu uwaga skierowana jest tylko na niego. Bioenergoterapeuta patrzy na pacjenta, wymienia jego dolegliwości i miejsca bólu, a czyni to bez nowoczesnej aparatury i autorytwatywnej wyższości nad petentem." 
                        </h2>
                        <br></br>
                        <h2 style={{textDecoration:"underline"}}>Medycyna alternatywna ma wspomagać medycynę akademicką, a nie ją podważać, ma być dodatkową pomocą dla lekarzy.</h2>
                        </div> 
                        <div className='med-content1-img'>
           <img src="bio1.jpg" alt="Bioenergoterapia" ></img>
           <h3>Rys.1 Bioenergoterapeuta z pacjentem</h3></div>
        </div> 

        <hr></hr> 
       
        <div className='med-content1'>
            
            <div className='med-content1-text'>
                    <h1 id="pojecia">Ważne pojęcia</h1>
                    <br></br>
                    <h2>Każdy organizm człowieka posiada dwa typy struktur:
                    </h2>
                    <br></br>
                    <h2>MERIDIANY - kanały energetyczne łączące narządy wewnątrz organizmu. Zaburzenie, zablokowanie jednego z nich powoduje chorobę w organiźmie człowieka.</h2>
                    <h2>CZAKRY - centra energetyczne, wychodzące na powierzchnię skóry, które "zassysają" energię z zewnątrz. Czakr jest ok. 1 tys, 8 dużych, 24 średnie, 118 małych, reszta ma rozmiary mikro. Zaburzenie przepustowości czakr lub meridianów jest wynikiem powstawania róznych schorzeń.</h2>
                    <br></br>
                    <h2>AURA - otacza ciało człowieka, jeżeli jest zaburzona to wynikiem jest choroba ciała fizycznego, czyli naszego organizmu. </h2>
                    <h2>BIOENERGOTERAPIA - polega na stosowaniu różnych technik mających na celu oczyszczenie miejsc zaburzonych i zasilenie pacjenta dobrą energią.</h2>
                   
                    </div>
                    <div className='med-content1-img'>
                <img src="czakry1.jpg" alt="Czakry"></img>
                <h3>Rys2. Czakry u ludzi</h3>
                <br></br>
                <img src="czakry_animal.jpg" alt="Czakry u zwierząt"></img>
                <h3>Rys.3 Czakry u zwierząt domowych</h3>
                </div>
        </div>

        <hr></hr> 

        <div className='med-content1'>
            
            <div className='med-content1-text'>
                    <h1 id="omnie">O mnie</h1>
                    <br></br>
                    
                    <h2>W swojej pracy z pacjentem używam głównie dotyku rąk, ale często wspomagam się różdżkami i wahadełkami, które działają na zasadzie anten. Oprócz wiedzy o funkcjonowaniu organizmu ludzkiego posiadam podwyższoną zdolność do odbierania i przekazywania energii. Oprócz medycyny naturalnej interesuję się czysto męskimi pasjami czyli militariami oraz motoryzacją, kocham wodę, a dla równowagi posiadam też artystyczną duszę - tworzę własnoręczne mandale.</h2>
                   
                    </div>
                    <div className='med-content1-img'>
                        <img src="IMG_6630.JPG" alt="Zbigniew Pełszyński" ></img>
                        <h3>Zbyszek Pełszyński</h3>
                        <br></br>
                </div>
        </div>

        <hr></hr> 

        <div className='med-content1'>
           <div className='med-content1-img'>
                    </div>
                <div className='med-content1-text'>
                    <h1 id="uslugi">Usługi</h1>
                    <br></br>
                        <h2>Dużo ludzi nie zdaje sobie sprawy z tego, że na przedmiotach codziennego użytku może znajdować się negatywna energia, powstała np. na skutek zazdrości drugiego człowieka. Czy podczas jazdy często spotykacie Państwo rożnego rodzaju nieprzyjemne sytuacje na drodze? Może ktoś ze znajomych zazdrości nowego auta, a może mechanik miał spory problem w naprawie? W takiej sytuacji należy okadzić oraz oczyścić odpowiednio dane auto. Takie sytuacje nie tylko tyczą się samochodów, oczyszczać należy domy, biura, nowo zakupione nieruchomości. Przy budowie nowego domu oferuję odnalezienie na mapce i działce cieków wodnych oraz doradztwo w rozmieszczeniu pewnego rodzaju pomieszczeń, aby nowy dom był domem szczęśliwym.</h2>
                        <br></br>
                        <div className='med-content1-img'>
                           
                            <img src="rozdzka.jpg" alt="Szukanie wody"></img>
                            <h3>Rys.4 Różdzka - przyrząd radiestezyjny</h3>
                            <br></br>
                            <img src="wahadlo.png" alt="Wahadło Isis"></img>
                            <h3>Rys.5 Moje ulubione wahadło - isis</h3>
                            </div>
                           <br></br> 
                           <br></br> 
                           <br></br> 
                        <h2> Przedstawione ceny, są cenami orientacyjnymi</h2>
                        <table>
                                <tr>
                                    
                                    <th style={{width:"75%"}}>Usługi</th>
                                    <th>Cena</th>
                                </tr>
                                <tr>
                                    <td ><span style={{ fontWeight:"600"}}>KONSULTACJA</span> <br></br>Indywidualna ocena stanu zdrowia i stanu energetycznego danej osoby</td>
                                    <td style={{textAlign:"center"}}><span style={{ fontWeight:"600"}}>150</span> zł (I wizyta), <br></br><span style={{ fontWeight:"600"}}>120</span> zł (kolejne wizyty)</td>
                                </tr>
                                <tr>
                                    <td ><span style={{ fontWeight:"600"}}>DIAGNOZA STANU ZDROWIA DANEJ OSOBY</span> (ze zdjęcia)</td>
                                    <td style={{textAlign:"center"}}><span style={{ fontWeight:"600"}}>200</span> zł</td>
                                </tr>
                                <tr>
                                    <td ><span style={{ fontWeight:"600"}}>OCZYSZCZANIE ENEREGTYCZNE</span></td>
                                    <td style={{textAlign:"center"}}><span style={{ fontWeight:"600"}}>100</span> zł</td>
                                </tr>
                                <tr>
                                    <td ><span style={{ fontWeight:"600"}}>OCZYSZCZANIE AUTA</span> <br></br>ze złych energii</td>
                                    <td style={{textAlign:"center"}}><span style={{ fontWeight:"600"}}>300</span> zł</td>
                                </tr>
                                <tr>
                                    <td ><span style={{ fontWeight:"600"}}>OCZYSZCZANIE DOMU/ MIESZKANIA</span> <br></br>ze złych energii, poczepów, bytów (indywidualne ustalenie ceny)</td>
                                    <td style={{textAlign:"center"}}>od <span style={{ fontWeight:"600"}}>200</span> zł </td>
                                </tr>
                                <tr>
                                    <td><span style={{ fontWeight:"600"}}>OCZYSZCZANIE BIURA</span> <br></br>stabilizacja dobrej energii w miejscu pracy, wskazanie cieków wodnych (indywidualne ustalenie ceny)</td>
                                    <td style={{textAlign:"center"}}>od <span style={{ fontWeight:"600"}}>400</span> zł</td>
                                </tr>
                                <tr>
                                    <td  ><span style={{ fontWeight:"600"}}>MASAŻ REIKI II STOPNIA</span></td>
                                    <td style={{textAlign:"center"}}><span style={{ fontWeight:"600"}}>150</span> zł</td>
                                </tr>
                                <tr>
                                    <td  ><span style={{ fontWeight:"600"}}>DORADZTWO ŻYCIOWE</span> <br></br>3 proste pytania przy pomocy wahadła</td>
                                    <td style={{textAlign:"center"}}><span style={{ fontWeight:"600"}}>100</span> zł</td>
                                </tr>
                                <tr>
                                    <td  ><span style={{ fontWeight:"600"}}>SZUKANIE CIEKÓW WODNYCH, WODY POD STUDNIE NA DZIAŁKACH</span> <br></br>(indywidualne ustalenie ceny)</td>
                                    <td style={{textAlign:"center"}}><span style={{ fontWeight:"600"}}>400</span> zł + dojazd</td>
                                </tr>
                        </table>
                       
                        </div>  
        </div> 

        <hr></hr> 

        <div className='med-content1'>
           <div className='med-content1-img'>
                    </div>
                <div className='med-content1-text'>
                    <h1 id="zabieg">Zabieg</h1>
                    <br></br>
                        <h2>Zabiegi dostosowuje indywidualnie do każdego pacjenta, dolegliwości. Każdy zabieg jest poprzedzony rozmową/wywiadem z pacjentem. 
                            Należy tu podkreślić, że każdy człowiek jest kowalem swojego losu, dlatego też zabiegi można potraktować jako wspomaganie w uzdrawianiu duszy i ciała. 
                            Każda osoba posiada swoją wolę i jest odpowiedzialna za swoje postępowanie, dlatego też nie można składać gwarancji na przyszłość. 
                            Wszystkie zabiegi są nieinwazyjne, każda osoba może odczuwać działanie energii w inny sposób.
                            <p>Przyjmuję w gabinecie lekarskim w Białymstoku przy ul. Al. Józefa Piłsudskiego 6/3, istnieje możliwośc dojazdu do pacjenta po wcześniejszym ustaleniu.</p>
                            <br></br>
                            <p style={{fontWeight:"600"}}>Bioenergoterapeuta nie leczy, tylko uzdrawia, leczy lekarz. Bioenergoterapeuta nigdy w 100% nie zastąpi lekarza medycyny akademickiej, ale może mu pomóc.</p>
                            </h2>
                        
                      
                        </div> 
                        
        </div> 

       {/* <hr></hr> 
        
        <div className='gin-content1'>
                <div className='gin-content1-text' style={{width:"100%"}}>
                <h1 id="biomag" style={{textAlign:"center"}}>Terapia Biomag</h1>
                    <br></br>
                    <h2>Gabinet lekarski wyposażony jest w najnowocześniejszy sprzęt Biomag Lumina. Jest to zastosowanie pulsacyjnego pola magnetycznego niskich częstotliwości 3D i światła spolaryzowanego. 
                      Oferuję przeprowadzenie zabiegu z wykorzystaniem sprzętu Biomag Lumina przy leczeniu wielu schorzen, m.in: bóle zwyrodnieniowe, bóle bioder, szumy w uszach, migreny, arytmia, wrzody żołądka, nadciśnienie, cukrzyca, reumatyzm, żylaki, obrzęki, kłopoty ze wzrokiem oraz wiele innych.
                    </h2>
                    
                      <br></br>
                      <h2 style={{fontStyle:"italic", fontWeight:"600"}}>Terapia Biomag nie ma skutków ubocznych i jest bardzo korzystna jako element rehabilitacji. Potrafi także odegrać istotną rolę w procesie kompleksowego leczenia
                      </h2>
                      <br></br>
                      <h2>Aby efekt był widoczny oraz zadawalający należy przeprowadzić kilka zabiegów z rzędu. Regularne stosowanie pomaga w leczeniu i profilaktyce wielu poważnych schorzeń. Każdy zabieg o częstotliwości 
                        od 1 do 81 Hz zawiera w sobie pozytywne efekty terapeutyczne o różnym stopniu intensywności. Pojedyńczy zabieg trwa 20 minut, jednak w niektórych przypadkach zabieg można wydłużyć do 90 minut.
                      </h2>
                      <br></br>
                      <div className='div-podzial' style={{float:"left"}}>
                      <h2 style={{ fontWeight:"600"}}>Podstawowe efekty stosowania: </h2>
                      <br></br>
                      <h2>przeciwbólowe (analgetyczne)</h2>
                      <h2>miorelaksacyjne (rozluźniające)</h2>
                      <h2>przeciwobrzękowe</h2>
                      <h2>wazodylatacyjne (rozszerzające naczynia krwionośne)</h2>
                      <h2>detoksykacyjne (usuwanie produktów przemiany materii)</h2>
                      <h2>gojące i regenerujące</h2>
                      <br></br>
                      </div>
                      <div className='div-podzial' >
                      <h2 style={{ fontWeight:"600"}}>Korzyści z regularnego stosowania Terapii Biomag: </h2>
                      <br></br>
                      <h2>Leczenie powikłań po COVID-19</h2>
                      <h2>Działanie przeciwbólowe</h2>
                      <h2>Wzmocnienie systemu immunologicznego</h2>
                      <h2>Obniżenie ciśnienia krwi</h2>
                      <h2>Regeneracja układu kostno-stawowego</h2>
                      <h2>Wspomaganie działania leków</h2>
                      <h2>Zwiekszenie dokrwienia i dotlenienia tkanek</h2>
                      <h2>Regeneracja błony komórkowej, czyli wspomaganie wchłaniania tlenu, składników odżywczych oraz przyjmowanych leków</h2>
                      <h2>Normalizacja pracy narządów wewnętrznych</h2>
                      <h2>Przyspieszenie procesu zrostu kostnego i gojenia po złamaniach</h2>
                      </div>
                      <br></br>
                      <div className='gin-img'style={{textAlign:"center"}}>
                      <img src="biomag.png" alt="Aparat Biomag Lumina"></img>
                      <h3>Specjalistyczny sprzęt Biomag Lumina</h3></div>
                </div>

        </div> */}

        <hr></hr>
        <div className='med-content1'>
                <div className='med-content1-text'>
                <h1 id="biopol">Biopol</h1>
                <br></br>
                    <h2>Jestem zaszczycony być członkiem <span style={{ fontWeight:"600"}}>Polskiego Stowarzyszenia Bioterapeutów BIOPOL</span> od 2021 roku. Chciałbym podziękować stowarzyszeniu oraz wszystkim przybyłym na tegoroczny <span style={{ fontWeight:"600"}}>Wiosenny Zjazd</span>, który odbył się w Warszawie. Spedziłem naprawdę miło czas, poznałem wspaniałych ludzi oraz nowe interesujące zagadnienia ze świata ezoterycznego.</h2>
                    <br></br>
                      <div className='zjazdi-img'>
                      <img src="zjazd.jpg" alt="Członkowie Wiosennego Zjazdu BIOPOL"></img>
                      <h3>Członkowie Wiosennego Zjazdu BIOPOL</h3></div>
                      <div className='zjazdi-img'>
                      <img src="zjazd1.jpg" alt="BIOPOL" height="100%"></img>
                      <h3>Polskie Stowarzyszenie Bioterapeutów BIOPOL</h3></div>
               </div>
        </div>

        
        <hr></hr>
        <div className='med-content1'>
                <div className='med-content1-text'>
                <h1 id="warsztaty">Warsztaty</h1>
                <br></br>
                    <h2> W listopadzie 2022 roku uczestniczyłem w niesamowitych warsztatach <span style={{ fontWeight:"600"}}>szamanizmu</span> pod kierownictwem <span style={{ fontWeight:"600"}}>Francoise Rambaud i Philippe Lacroze</span> w miejscowości Kawkowo. Poznałem wiele nowych technik oczyszczania siebie, myslenia pozytywnego oraz zasiewania w sobie dobrych wibracji. Serdecznie polecam Państwu uczestnictwo.</h2>
                    <br></br>
                      <div className='zjazdi-img'>
                      <img src="francoise.jpg" alt="Członkowie Wiosennego Zjazdu BIOPOL"></img>
                      <h3>Warsztaty szamanizmu</h3></div>
               </div>
        </div>

        <hr></hr> 

        <div className='med-content1'>
         
                <div className='med-content1-text'>
                    <h1 id="uprawnienia">Uprawnienia</h1>
                    <br></br>
                        
                      <div className='column'>
                      
                                <img src="1.jpeg"></img>
                                <img src="2.jpeg"></img>
                                <img src="4.jpeg"></img>
                                <img src="3.jpeg"></img>
                                <img src="5.jpeg"></img>
                                <img src="6.jpeg"></img>
                                <img src="IMG_8650_1.jpg"></img>
                               
                      </div>
                        </div> 
                        
        </div> 
        <hr></hr>
        <div className='med-content1'>
        <div className='med-content1-text' >
            <h1> Bibliografia</h1>
            <br></br>
            <h3 style={{textAlign:"right"}}> Rys.1 Bioenergoterapeuta z pacjentem, rysunek pochodzi z serwisu Amino</h3>
            <h3 style={{textAlign:"right"}}>online: <a href="https://aminoapps.com/c/realidadmultidimensional/page/item/barbara-ann-brennan/mogp_5Qmh0IkaqDBlvlkZRKDxz1DRWzlmd" target="_blank" style={{wordWrap: "break-word"}}>https://aminoapps.com/c/realidadmultidimensional/page/item/barbara-ann-brennan/mogp_5Qmh0IkaqDBlvlkZRKDxz1DRWzlmd</a>, dostęp: 26.07.2021.</h3>
            <h3 style={{textAlign:"right"}}> Rys.2 Czakry u ludzi, autor nieznany, rysunek znaleziony na portalu Pinterest</h3>
            <h3 style={{textAlign:"right"}}>online: <a href="https://pl.pinterest.com/" target="_blank" style={{wordWrap: "break-word"}}>https://pl.pinterest.com/</a>, dostęp: 26.07.2021.</h3>
            <h3 style={{textAlign:"right"}}> Rys.3 Czakry u zwierząt domowych, autor nieznany, rysunek znaleziony na portalu Pinterest</h3>
            <h3 style={{textAlign:"right"}}>online: <a href="https://pl.pinterest.com/pin/12877548923169599/" target="_blank" style={{wordWrap: "break-word"}}>https://pl.pinterest.com/pin/12877548923169599/</a>, dostęp: 26.07.2021.</h3>
            <h3 style={{textAlign:"right"}}> Rys.4 Różdzka - przyrząd radiestezyjny, rysunek zaczerpnięty z portalu Biosłone</h3>
            <h3 style={{textAlign:"right"}}>online: <a href="https://portal.bioslone.pl/bioenergoterapia/oddzialywania_energetyczne" target="_blank" style={{wordWrap: "break-word"}}>https://portal.bioslone.pl/bioenergoterapia/oddzialywania_energetyczne</a>, dostęp: 26.07.2021.</h3>
            <h3 style={{textAlign:"right"}}> Rys.5 Wahadło ISIS, zdjęcie zaczerpnięte z serwisu Nieznany Świat</h3>
            <h3 style={{textAlign:"right"}}> online: <a href="https://nieznany.pl/pl/p/Wahadlo-IZIS-metalowe-duze/3707" target="_blank" style={{wordWrap: "break-word"}}>https://nieznany.pl/pl/p/Wahadlo-IZIS-metalowe-duze/3707</a>, dostęp: 26.07.2021.</h3>
            
            </div>
        </div>
    </div>
    );
}

export default MedSection;