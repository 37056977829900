import React from 'react';
import Navbar from './components/Navbar';
import HomeSection from './components/HomeSection';
import AboutSection from './components/AboutSection';
import Contact from './components/pages/Contact';
import Gin from './components/pages/Gin';
import MedSection from './components/MedSection';
import RodoSection from './components/RodoSection';
import {HashRouter, Switch, Route} from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';

function App() {
  return (
    <>
    <BrowserRouter>
     <Navbar sticky="top"/>
     <Switch>
          <Route path='/' exact component={HomeSection} />
          <Route path='/medycyna-alternatywna' component={MedSection} />
          <Route path='/ginekologia-poloznictwo' component={Gin} />
          <Route path='/rodo' component={RodoSection} />
          <Route path='/galeria' component={AboutSection} />
          <Route path='/kontakt' component={Contact} />
     </Switch>
     <Header/>
     </BrowserRouter>
    </>
  );
}

export default App;
