import { render } from '@testing-library/react';

import '../../App.css';
import '../GinSection.css';
import {Link} from 'react-router-dom';
import  { useEffect } from "react";
import { Helmet } from "react-helmet";
import React, {Component} from "react";

class Gin extends React.Component {
  constructor(props) {
    super(props);
    this.switchImage = this.switchImage.bind(this);
    this.state = {
      currentImage: 0,
      images: [
        "IMG_8882.JPG",
        "galeria7.jpg",
        "galeria6.jpg"
        
      ]
    };
  }

  useEffect = (() => {
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  switchImage() {
    if (this.state.currentImage < this.state.images.length - 1) {
      this.setState({
        currentImage: this.state.currentImage + 1
      });
    } else {
      this.setState({
        currentImage: 0
      });
    }
    return this.currentImage;
  }

  componentDidMount() {
    setInterval(this.switchImage, 3000);
  }

  render() {
  return (
    <div className='gin-container'>
          <div className='gin-top'>
            <div className='gin-top-text'>
            <h2 style={{fontWeight:"600"}}>Ginekologia i położnictwo</h2>
            <br></br>
            <h3><Link className='gin-a' to="#doswiadcznie" onClick={() => {let hero = document.getElementById("doswiadcznie"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}>Doświadczenie</Link>  •	 <Link className='gin-a' to="#omnie" onClick={() => {let hero = document.getElementById("omnie"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}>O mnie</Link>  •	 <Link className='gin-a' to="#uslugi" onClick={() => {let hero = document.getElementById("uslugi"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}>Usługi</Link> •	<Link className='gin-a' to="#certyfikaty" onClick={() => {let hero = document.getElementById("certyfikaty"); hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });}}>Certyfikaty</Link> </h3></div>
          </div>
        <div className='gin-content1'>
            <div className='gin-content1-text'>
                            
                            
                            <img id="switch-img" src={this.state.images[this.state.currentImage]} ></img>
                           
                    <h1 id="doswiadcznie" style={{marginTop:"5%"}}>Doświadczenie</h1>
                    <br></br>
                    <h2>Pracuję w zawodzie od niemal 40 lat. Ukończyłam w 1982 roku Akademię Medyczna w Białymstoku, a w 1991 roku osiągnełam II stopień specjalizacji ginekologii i położnictwa.</h2>
                    <br></br>
                    <h2>Oferuję opiekę medyczną dla Kobiet w różnym wieku (okres pokwitania, ciąża, okres przekwitania - klimakterium). Profilaktyka raka szyjki macicy, antykoncepcja, badania cytologiczne oraz badania USG (piersi, położnicze, ginekologiczne). Gabinet przy ul. Al. Jozefa Piłsudskiego 6/3 prowadzę od 2014 roku.                     </h2>
                    <br></br>
                    <h1 id="omnie">O mnie</h1>
                    <br></br>
                    <h2>Pragnę pomagać z zaangażowaniem oraz traktować wszystkie swoje pacjentki równie dobrze. Staram się zawsze wysłuchać oraz odpowiedzieć na wszelkie niejasności związane ze zdrowiem Kobiet, ponieważ sama nią jestem. </h2>
                    <br></br>
                    <h2>Elżbieta Olechno-Pełszyńska</h2>
                    <br></br>
                    <div className='gin-uslugi-div' >
                        <div className='gin-content1-text' >
                        <a href="https://www.orlymedycyny.pl/profile-677526-gabinet-ginekologiczny-dr-elzbieta-olechno-pelszynska-ginekolog-bialystok" target="_blank">
                          <img src="https://www.orlymedycyny.pl/images/medals/677526/laureat300_black_2_pl.png" style={{width:"100%"}} alt="Gabinet Ginekologiczny dr Elżbieta Olechno-Pełszyńska Ginekolog Białystok -  Białystok" title="Gabinet Ginekologiczny dr Elżbieta Olechno-Pełszyńska Ginekolog Białystok -  Białystok"></img></a>
                          </div></div>
                      <br></br>
                   
            </div>

           
        </div>

        <hr></hr> 

        <div className='gin-content1'>
      
                          
                <div className='gin-content1-text' style={{width:"100%"}}>
                <h1 id="uslugi">Usługi</h1>
                    <br></br>
                    <h2 >Gabinet posiada uprawnienia do wydawania recept refundowanych oraz zwolnień lekarskich. W ramach konsultacji ginekologicznej, wykonuje stopień czystości.</h2>
                    
                        <table >
                                <tr>
                                    
                                    <th style={{width:"75%"}}>Usługi</th>
                                    <th >Cena</th>
                                </tr>
                                <tr>
                                    <td >Konsultacja ginekologiczna </td>
                                    <td style={{textAlign:"center"}}>150 zł</td>
                                </tr>
                                <tr>
                                    <td >Konsultacja położnicza </td>
                                    <td style={{textAlign:"center"}}>150 zł</td>
                                </tr>
                                <tr>
                                    <td  >Badanie ginekologiczne z oceną biocenozy pochwy (stopień czystości)</td>
                                    <td style={{textAlign:"center"}}>200 zł</td>
                                </tr>
                                <tr>
                                    <td >Badanie cytologiczne (profilaktyka raka szyjki macicy)</td>
                                    <td style={{textAlign:"center"}}>od 50 zł</td>
                                </tr>
                                <tr>
                                    <td >USG (ginekologiczne)</td>
                                    <td style={{textAlign:"center"}}>100 zł</td>
                                </tr>
                                <tr>
                                    <td >USG (położnicze)</td>
                                    <td style={{textAlign:"center"}}>250 zł</td>
                                </tr>
                                <tr>
                                    <td  >Założenie wkładki domacicznej</td>
                                    <td style={{textAlign:"center"}}>500 zł + cena wkładki</td>
                                </tr>
                                <tr>
                                    <td  >Wyjęcie wkładki domacicznej</td>
                                    <td style={{textAlign:"center"}}>100 zł</td>
                                </tr>
                             
                                <tr>
                                    <td >Recepta </td>
                                    <td style={{textAlign:"center"}}>50 zł</td>
                                </tr>
                            
                        </table>
                        <br></br>

                        



                        <div className='gin-uslugi-div'style={{display:"inline-block"}} >
                        <div className='gin-content1-text' >
                          <img src="cytologia.jpg" alt="Badanie cytologiczne" style={{display:"inline-block",  borderRadius: "5%"}} ></img>
                          <br></br>
                          <br></br>
                          <h1>Cytologia</h1>
                          <br></br>
                          <h2>Cytologia szyjki macicy jest badaniem specjalistycznym, pomaga wykryć m.in. wirusa HPV, który ma bezpośredni związek z rozwojem choroby nowotworowej. 
                            Cytologia powinna być wykonywana regularnie. Badanie jest wykonywane poprzez wziernik, a także szczoteczkę, którą pobiera się materiał do analizy. 
                            Wymaz pobrać można co najmniej 2 dni po zakończonej miesiączce lub najpóźniej 4 dni przed kolejną miesiączką. 
                            Optymalnym czasem do pobrania próbki jest pierwsza połowa cyklu. </h2>
                            
                            <br></br>
                            <br></br>
                            <h1>USG ginekologiczne</h1>
                          <br></br>
                          <h2>USG ginekologiczne jest badaniem obrazowym, zlecanym przez lekarza, jest badaniem profilaktycznym jak np. cytologia. Pozwala sprawdzić stan kobiecych narządów rodnych. Jak działa USG? Głowica aparatu USG wysyła sygnał przechodzący przez tkanki, a jej odbicie powraca do odbiornika, który przetwarza falę na obraz.
                            Są dwa rodzaje badania USG, transwaginalne (dopochwowe) oraz przezbrzuszne. USG waginalne jest bardziej dokładne. USG ginekologiczne najlepiej wykonać kilka dni po zakończeniu miesiączki. A przygotowując się do badania, najlepiej jest opróżnić swój pęcherz moczowy.
                            USG ginekologiczne jest bezpieczne i nieinwazyjne. Przeciwskazaniem do jego wykonania są otwarte rany pochwy oraz brak zgody pacjentki na jego wykonanie.</h2>

                                                    
                            <img src="biorezonans.jpg" alt="Biorezonans magnetyczny" style={{display:"inline-block", borderRadius: "5%"}} ></img>
                            <br></br>
                            <br></br>

                        <h1>Kwantowy Analizator Stanu Zdrowia</h1>
                          <br></br>
                          <h2>Został opracowany na podstawie badań przeprowadzonych na astronautach. 
                            Sygnały fal elektromagnetycznych emitowanych przez ciało człowieka różnią się w zależności od stanu poszczególnych organów. W kilka minut pozwoli dowiedzieć się, jaki jest poziom witamin, minerałów, aminokwasów i enzymów w organizmie. 
                            Do badania należy zdjąć wszystkie metalowe ozdoby przylegające do ciała oraz być naczczo 1,5h przed badaniem.</h2>
                            
                            </div>
                        
                        </div>
                </div>
        </div>
{/* 
        <hr></hr> 

        <div className='gin-content1'>
                <div className='gin-content1-text' style={{width:"100%"}}>
                <h1 id="med">Ziołolecznictwo</h1>
                    <br></br>
                    <h2>Interesuję się medycyną naturalną m.in. ziołolecznictwem  czyli fitoterapią.</h2>
                    <h2>Zioła swoje dobroczynne działanie zawdzięczają zawartym w sobie substancjom, które usprawniają ludzki organizm.</h2>
                    <br></br>
                    <h2>Ziołolecznictwo, leczenie zgodnie z naturą:</h2>
                    <h2>- Profilaktyka- zabopieganie chorobom przy pomocy ziół</h2>
                    <h2>- Leczenie chorób przy pomocy ziół</h2>
                    <br></br>
                    <h2>Można stosować preparaty ziołowe gotowe i do samodzielngo sporządzania.</h2>
                    <h2>Zachęcam pacjentów do łączenia  medycyny akademickiej z medycyną naturalną, ponieważ z doświadczenia wiem, że stosowane razem dają najlepsze korzyści w leczeniu naszego zdrowia. Leczenie ziołami powinno być pod kontrolą lekarza, ponieważ niektóre zioła w nadmiarze mogą szkodzić zdrowiu.</h2>
                    <br></br>
                    <h2>Podstawowe korzyści z zastosowania odpowiednich ziół m.in.</h2>
                    <h2>- Poprawa metabolizmu </h2>
                    <h2>- Poprawa jakości snu </h2>
                    <h2>- Poprawa samopoczucia </h2>
                    <h2>- Właściwości kosmetyczne </h2>
                    <div className='gin-img'style={{textAlign:"center"}}>
                      <img src="herbs.jpg" alt="Ziołolecznictwo" ></img></div>
                </div>
        </div> */}

        <hr></hr> 

        <div className='gin-content1'>
                <div className='gin-content1-text' style={{width:"100%"}}>
                <h1 id="certyfikaty">Certyfikaty</h1>
                    <br></br>
                     <div className='gallery-container'>
                        <div class="row"> 
                        <div class="column-gallery">
                        <img src="37.jpeg" ></img>
                        <img src="36.jpeg"></img>
                        <img src="35.jpeg" ></img>
                        <img src="34.jpeg" ></img>
                        </div>

                        <div class="column-gallery">
                        <img src="33.jpeg" ></img>
                        <img src="32.jpeg"></img>
                        <img src="31.jpeg" ></img>
                        <img src="30.jpeg" ></img>
                        </div>

                        <div class="column-gallery">
                        <img src="29.jpeg" ></img>
                        <img src="28.jpeg"></img>
                        <img src="27.jpeg" ></img>
                        <img src="26.jpeg" ></img>
                        <img src="25.jpeg" ></img>
                        <img src="24.jpeg" ></img>
                        </div>

                        <div class="column-gallery">
                        <img src="23.jpeg" ></img>
                        <img src="22.jpeg"></img>
                        <img src="21.jpeg" ></img>
                        <img src="20.jpeg" ></img>
                        </div>

                        <div class="column-gallery">
                        <img src="19.jpeg" ></img>
                        <img src="18.jpeg"></img>
                        <img src="17.jpeg" ></img>
                        <img src="16.jpeg" ></img>
                        </div>

                        <div class="column-gallery">
                        <img src="15.jpeg" ></img>
                        <img src="14.jpeg"></img>
                        <img src="13.jpeg" ></img>
                        <img src="12.jpeg" ></img>
                        <img src="3.3.jpeg" ></img>
                        </div>

                        
                        <div class="column-gallery">
                        <img src="11.jpeg" ></img>
                        <img src="10.jpeg"></img>
                        <img src="9.jpeg" ></img>
                        <img src="8_1.jpeg" ></img>
                        <img src="2.2_1.jpeg"></img>
                        </div>

                        
                        <div class="column-gallery">
                        <img src="7.jpeg" ></img>
                        <img src="6.6.jpeg"></img>
                        <img src="5.5.jpeg" ></img>
                        <img src="4.4.jpeg" ></img>
                        <img src="1.1.jpeg" ></img>
                        </div>

                    </div>
                    </div>
                    </div>
                   
                
        </div>
    </div>
  );
}
}
export default Gin;